.dashboard {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

.ad-dash-title {
  color: var(--tertiary-color);
  margin-top: 6vh;
  margin-left: 15vw;
  margin-bottom: 1.5vh;
  font-size: 40px;
}

.searches {
  margin-top: 2vh;
  width: 100%;
  height: 6vh;
}

.selector {
  width: 12vw;
  height: 4vh;
  position: absolute;
  right: 36vw;
  margin-right: 5vw;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
  font-size: 16px;
  padding: 3px;
}

@media (max-width: 768px) {
  .searches {
    display: flex;
    flex-direction: column;
    height: 12vh;
    width: 10vw;
    margin-left: 50vw;
  }
  .selector {
    position: relative !important;
    margin-right: 5vw;
    margin-bottom: 15px;
    width: fit-content;
  }

  .find-selector {
    position: relative !important;
    width: 50vw !important;
    padding-left: 0;
    min-height: 3vh !important;
    margin-bottom: 15px;
  }

  .apply-filter {
    position: relative !important;
    width: 25vw !important;
    padding: 10px !important;
    /* margin-bottom: 100px; */
  }

  .get-users {
    margin-top: 5vh !important;
  }
}

.find-selector {
  width: 12vw;
  height: 4vh;
  position: absolute;
  right: 20vw;
  margin-right: 5vw;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
}

.apply-filter {
  height: 4vh;
  position: absolute;
  right: 10vw;
  margin-right: 5vw;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  width: fit-content;
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
  border: none;
  border-radius: 8px;
}

.find-selector::placeholder {
  color: var(--tertiary-color);
}

.get-users {
  min-height: 69vh;
  width: 70vw;
  margin-left: 15vw;
  margin-top: 2.5vh;
}

.pages {
  color: var(--tertiary-color);
  margin-left: 10px;
  margin-right: 10px;
}

.t-button {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  width: fit-content;
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
  border: none;
  border-radius: 8px;
}

.ad-users-bottom-div {
  margin-bottom: 30px;
}
