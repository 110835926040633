.lf-s25-outer {
  /* background-color: var(--tertiary-color); */
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.lf-s25-label {
  display: flex;
  flex-direction: column;
  color: var(--tertiary-color);
  text-align: left;
  margin-left: 3px;
  font-size: 14.5px;
}

.lf-s25-input {
  width: 75%;
  background-color: transparent;
  border-radius: 8px;
  height: 1.5rem;
  margin-top: 6px;
  margin-bottom: 7px;
}

.lf-s25-p {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--tertiary-color);
  font-size: 14.5px;
}

.lf-s25-a {
  color: var(--tertiary-color);
  text-decoration: none;
  margin-bottom: 8px;
}

@media (max-width: 475px) {
  .lf-s25-button {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 8px;
    padding-left: 8px;
    background-color: var(--tertiary-color);
    border: none;
    font-size: 13px;
    color: var(--quaternary-color);
    text-decoration: none;
    border-radius: 5px;
  }

  .lf-s25-p {
    flex-direction: row !important;
    justify-content: center;
    padding: 0 !important;
  }

  .lf-s25-a {
    margin-right: 10px !important;
  }

  .lf-s25-error-message {
    margin-bottom: 10px !important;
    margin-top: 0;
  }
}

.lf-s25-button {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 13px;
  padding-left: 13px;
  background-color: var(--tertiary-color);
  border: none;
  font-size: 16px;
  color: var(--quaternary-color);
  text-decoration: none;
  border-radius: 8px;
}

.lf-s25-error-message {
  font-size: 15px;
  color: #c70314;
  margin-bottom: 15px;
}
