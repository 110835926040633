.dashboard {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

.row {
  margin-top: 3.5vh;
}

.ad-dash-title {
  color: var(--tertiary-color);
  margin-top: 6vh;
  margin-left: 20vw;
  margin-bottom: 1.5vh;
  font-size: 40px;
}

.stats-cards-div {
  margin-top: 50px;
  min-height: 78vh;
  margin-left: 20vw;
}

.stats-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative; /* Ensure tooltip positioning works */
}

.stats-card {
  background: var(--tertiary-color);
  border-radius: 10px;
  padding: 20px;
  width: calc(33.333% - 20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
  position: relative; /* Ensure tooltip positioning works */
}

.stats-card:hover {
  background: var(--color-five);
}

.stats-card h3 {
  margin: 0;
  font-size: 1.2em;
  color: var(--background-color);
}

.stats-card p {
  margin: 10px 0 0;
  font-size: 1.5em;
  font-weight: bold;
  color: var(--background-color);
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  transition: opacity 0.3s; /* Add transition for smooth fade */
}

.stats-card:hover .tooltip {
  opacity: 0.8; /* Show on hover */
}

/* .cards {
  min-height: 78vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  margin-left: 20vw;
}

.level-cards {
  display: flex;
  flex-direction: row;
}

.s-card {
  margin-top: 5vh;
  margin-right: 10vw;
  width: 15vw;
  padding-right: 2vw;
  height: 20vh;
  background-color: var(--tertiary-color);
  padding-bottom: 3vh;
}

.s-card-title {
  color: var(--quaternary-color);
  margin-left: 1.2vw;
  width: 100%;
  text-align: center;
}

.s-card-desc {
  color: var(--quaternary-color);
  margin-left: 1.2vw;
  width: 100%;
  text-align: center;
}

.link {
  text-decoration: none;
} */
