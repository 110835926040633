.ra-no-review {
  color: var(--tertiary-color);
  margin-top: 2.5vh;
  margin-left: 15vw;
  margin-bottom: 1.5vh;
  font-size: 22px;
}

.no-more-h1 {
  width: 100vw;
  text-align: center;
  background-color: var(--background-color);
  color: red;
  margin: 0;
  padding-top: 5vh;
}
