.acceptances-input {
  margin-top: 10px;
  min-height: 13vh;
  height: 13vh;
  width: 50vw;
  padding: 7px;
  border: 2px solid var(--secondary-color);
  background-color: var(--background-color);
  color: var(--tertiary-color);
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-bottom: 15px;
}

.acceptances-emails {
  color: var(--tertiary-color);
  font-size: 22px;
  width: 15vw;
  height: fit-content;
  flex-wrap: wrap;
}

.accepted-or-failed-div {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.accepted-div {
  margin-right: 300px;
  margin-left: 300px;
  width: 15vw;
  display: flex;
  flex-direction: column;
}

.accept {
  color: var(--tertiary-color);
}

.batch-select {
  width: 25vw;
  background-color: var(--background-color);
  border: 2px solid var(--secondary-color);
  color: var(--tertiary-color);
  font-size: 16px;
  padding: 3px;
}

.batch-select-options {
  width: 25vw;
  background-color: var(--background-color);
  border: 2px solid var(--secondary-color);
  color: var(--tertiary-color);
  font-size: 16px;
  padding: 3px;
  margin-left: 20px;
}

.header-title-batch {
  color: var(--tertiary-color);
  margin-top: 6vh;
  margin-left: 21vw;
  margin-bottom: 1.5vh;
  font-size: 40px;
}

.button-batch {
  margin-top: 3vh;
  min-height: fit-content;
  height: 3vh;
  min-width: fit-content;
  width: 7vw;
  background-color: var(--tertiary-color);
  border: none;
  padding: 8px 13px 8px 13px;
  border-radius: 8px;
  font-weight: 520;
  font-size: 16px;
  color: var(--quaternary-color);
}

.batch-trav-re {
  margin-bottom: 1vw;
}
