.apply {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

.application {
  min-height: 90vh;
}

.apply-title-container {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.app-title {
  color: var(--tertiary-color);
  font-size: 40px;
  margin-top: 5vh;
  margin-bottom: 30px;
}

.apply-form {
  min-height: fit-content;
}

.css-13cymwt-control {
  background-color: var(--background-color);
}

.section-title {
  margin-left: 3vw;
  margin-bottom: 1.5vh;
  color: var(--background-color);
}

.app-form-question-div {
  display: flex;
  flex-direction: column;
  width: 84%;
  /* border: 1px dotted blue; */
  padding: 10px 8%;
}

.numeric {
  height: 25px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
  border: none;
}

.boolean {
  height: 25px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
}

.short-answer {
  height: 25px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.long-answer {
  height: 125px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
  overflow-x: hidden;
  overflow-y: auto;
  resize: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.multiple-choice {
  height: 25px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
}

.multi-select {
  height: 35px;
  width: 100%;
  color: var(--hp-black);
}

.list {
  height: 25px;
  width: 75%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
  border: none;
  margin-bottom: 10px;
}

.list-button {
  padding: 5px;
  margin: 5px;
  height: fit-content;
  width: fit-content;
}

.list-button-add {
  padding: 5px;
  height: fit-content;
  width: fit-content;
}

.autocomplete {
  height: 25px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
  border: none;
}

.autocomplete-items {
  display: flex;
  flex-direction: column;
}

.autocomplete-option {
  height: 16px;
  padding-top: 3px;
  padding-bottom: 7px;
  padding-left: 5px;
  background-color: var(--quaternary-color);
  color: var(--hp-black);
  font-size: 14px;
  cursor: pointer;
}

.resume-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  color: var(--quaternary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.app-form-div {
  background-color: var(--tertiary-color);
  margin-bottom: 5vh;
  padding-top: 2vh;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 3px dotted green; */
  width: 600px;
  border-radius: 10px;
}

.label {
  color: var(--quaternary-color);
  /* border: 2px dotted pink; */
  margin-bottom: 10px;
}

.label-other {
  color: var(--quaternary-color);
  /* border: 2px dotted pink; */
  margin-bottom: 10px;
  margin-top: 10px;
}

.label-other input {
  margin-top: 10px;
}

.star {
  color: red;
}

.b-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  /* margin-bottom: 180px; */

  button {
    position: relative;
    height: 100%;
    padding: 16px;
    margin: 10px 10px 0;
    /* text-transform: uppercase; */
    font-family: alegreya-sans, sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    color: var(--quaternary-color);
    border: none;
    background-color: var(--tertiary-color);
    border-radius: 10px;
  }

  /* .submit-button-a {
    background-color: var(--tertiary-color);
  }

  .save-button-a {
    background-color: #6fabff;
  } */
}

.p-class {
  color: var(--quaternary-color);
  font-size: 17px;
  line-height: 1.6;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 1.5vw;

  a {
    color: var(--secondary-color);
    text-decoration: none;
    font-weight: 500;
  }
}

.coc {
  margin-left: 10%;
  margin-right: 10%;

  div {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
  }

  input {
    align-self: flex-start;
    width: 5%;
    height: 15px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 1vh;
  }

  .checkbox-container input {
    margin-right: 8px;
  }
}

.coc-p {
  color: var(--quaternary-color);
  font-size: 17px;
  line-height: 1.6;
  margin-block-start: 0;
  margin-block-end: 0;
}

.coc-a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: 500;
}

.coc-l {
  color: var(--quaternary-color);
  flex: 1;
  margin: 0;
}

.upload-button {
  margin-top: 1vh;
  margin-bottom: 1.5vh;
  margin-left: 50px;
  padding: 10px;
  background-color: var(--background-color);
  border: none;
  position: relative;
  height: fit-content;
  text-transform: none;
  font-weight: normal;
  font-size: 15px;
  color: var(--tertiary-color);
}

.apply-form-outer-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 180px;
}

.team-code-desc {
  font-size: 12px;
  color: var(--background-color);
  margin-top: 0;
}

.resume-message {
  color: var(--background-color);
}

.resume-error {
  color: #c70314;
}

.appform-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: var(--tertiary-color);
}

.appform-message-error {
  text-align: center;
  margin-top: 20px;
  font-size: 22px;
  color: #c70314;
}

.css-13cymwt-control {
  background-color: var(--quaternary-color) !important;
  height: 35px;
  overflow-y: scroll;
}

.css-13cymwt-control::-webkit-scrollbar {
  display: none;
}

.css-13cymwt-control:hover {
  border: none !important;
}

.css-t3ipsp-control {
  background-color: var(--quaternary-color) !important;
}

.css-t3ipsp-control {
  border: none !important;
  box-shadow: none !important;
}

.css-1nmdiq5-menu {
  background-color: var(--quaternary-color) !important;
}

.css-1nmdiq5-menu:current {
  background-color: var(--quaternary-color) !important;
}
/* .css-13cymwt-control: {
  background-color: #c70314;
} */

.css-1u9des2-indicatorSeparator {
  background-color: var(--quaternary-color) !important;
  color: var(--tertiary-color) !important;
}

@media (max-width: 768px) {
  .section-title {
    margin-left: 8%;
  }

  .apply-title-container {
    width: 90%;
  }

  .app-form-div {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.af-teamcode-div {
  margin-top: 10px;
}

.short-answer-tc {
  height: 25px;
  width: 100%;
  background-color: var(--quaternary-color);
  color: var(--tertiary-color);
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-top: 10px;
}

.css-bp6yz8-control {
  background-color: var(--quaternary-color);
}
