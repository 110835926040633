.footer {
  display: flex;
  flex-direction: column;
  background-color: var(--tertiary-color);
  min-height: 12vh;
  height: auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.horizontal-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.horizontal-logos li {
  margin-left: 15px;
  margin-right: 15px;
}

.mini-image {
  padding-top: 2vh;
  padding-bottom: 1.2vh;
  height: 3vh;
  width: auto;
  filter: invert(90%);
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-text a {
  color: var(--quaternary-color);
  text-decoration: none;
  padding-bottom: 0.8vh;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 0.8rem;
  transition: 0.3s ease;
}

.footer-p {
  color: var(--quaternary-color);
  text-decoration: none;
  padding-bottom: 0.8vh;
  /* margin-left: 6px;
  margin-right: 6px; */
  font-size: 0.8rem;
  transition: 0.3s ease;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.footer-text a:hover {
  color: var(--secondary-color);
}

.footer-text-bottom {
  display: flex;
  flex-direction: row;
}
