html,
.body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.loading-container {
  height: 85vh;
  width: 100vw;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-container-component {
  height: 57vh;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
}
