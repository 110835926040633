@import url("../../root.css");

.register {
  width: 100vw;
  overflow-x: hidden;
  min-height: 80vh;
  background-color: var(--background-color);
}

.row-columns {
  min-height: 88vh;
  display: flex;
  flex-direction: row;
}

.auth {
  text-align: center;
  float: none;
  height: 100%;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.auth-par {
  font-size: 20px;
  color: var(--secondary-color);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Four shadows to create an outline */
}

.auth img {
  padding: 20px;
}

.error-message {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 16px;
  color: #c70314;
  margin-bottom: 7px;
  /* border: 2px dotted green; */
}

@media (max-width: 450px) {
  .complete-log-form {
    /* margin-left: 10vw; */
    margin-right: 10vw;
    width: 80vw;
  }

  .auth-form {
    width: 90vw;
    margin-left: 5vw;
  }

  .auth-form input {
    margin: 0 !important; /* Added !important to ensure it overrides */
    margin-bottom: 15px !important; /* Added !important to ensure it overrides */
  }

  .auth p {
    max-width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .logo {
    height: 24vh !important;
    width: auto;
  }

  .error-message {
    margin-top: 0;
    margin-left: 0;
    font-size: 16px;
    color: #c70314;
    margin-bottom: 7px;
  }
}

.auth-form {
  background-color: var(--tertiary-color);
  margin-top: 3vh;
  padding-top: 2vh;
  font-size: 20px;
  padding-bottom: 2vh;
  border-radius: 13px;
  /* opacity: 0.8; */
}

.subtitle {
  color: var(--background-color);
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.auth-form input {
  color: var(--tertiary-color);
  width: 75%;
  margin: 10px 50px 15px;
  border: 2px solid var(--tertiary-color);
  padding: 0 0.75em;
  background-color: var(--quaternary-color);
  height: 1.8rem;
}

.auth-form-password::-ms-reveal {
  filter: invert(100%);
}

.auth-form input:first-child {
  margin-top: 15px;
}

.auth-form input:focus {
  border-width: 2px;
}

.auth-form input::placeholder {
  color: var(--tertiary-color);
}

.auth-form button {
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 1.6rem;
  color: var(--quaternary-color);
  background-color: transparent;
  border: none;
  transition: 0.3s ease;
}

.auth-form button:hover {
  color: var(--secondary-color);
}

.auth-form input::placeholder {
  color: lighten(var(--tertiary-color), 20);
  font-style: normal;
}

.auth-form .auth-meta a {
  font-family: alegreya-sans, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--background-color);
  margin-bottom: 0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.auth-form .auth-meta a:hover {
  color: var(--secondary-color);
}

.auth-form .auth-form-inner {
  padding-left: 0;
  padding-right: 0;
  /* border: 2px dotted blue; */
}

.auth-form__lower {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.auth-form__message {
  flex-grow: 1;
  margin-top: 1.25em;
  margin-right: 1rem;
  text-align: right;
}

.page-meta {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.page-meta p {
  margin: 3px;
}

.title {
  margin-top: 20px;
  margin-bottom: 5px;
  color: var(--tertiary-color);
  font-size: 46px;
}

.logo {
  height: 25vh;
  width: auto;
}

.footer-reg {
  position: absolute;
}

.return {
  text-decoration: none;
  color: var(--background-color);
}

.login-sections {
  display: flex;
  flex-direction: row;
}
