.dashboard {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  padding-bottom: 5vh;
}

.row {
  margin-top: 3.5vh;
}

.ad-dash-title {
  color: var(--tertiary-color);
  margin-top: 6vh;
  margin-left: 20vw;
  margin-bottom: 1.5vh;
  font-size: 40px;
}

.cards {
  min-height: 78vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  margin-left: 20vw;
}

.level-cards {
  display: flex;
  flex-direction: row;
}

.card {
  margin-top: 5vh;
  margin-right: 10vw;
  width: 20vw;
  padding-right: 2vw;
  /* height: 16vh; */
  min-height: fit-content;
  background-color: var(--tertiary-color);
  padding-bottom: 3vh;
  border-radius: 8px;
}

.card-title {
  color: var(--quaternary-color);
  margin-left: 1.2vw;
}

.card-desc {
  color: var(--quaternary-color);
  margin-left: 1.2vw;
}

.link {
  text-decoration: none;
}

.admin-cards-div {
  min-height: 78vh;
  margin-left: 20vw;
}

.admin-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative; /* Ensure tooltip positioning works */
  justify-content: flex-start; /* Align cards to the left */
}

.admin-card {
  background: var(--tertiary-color);
  border-radius: 10px;
  padding: 20px;
  max-width: calc(33.333% - 20px);
  flex: 1 1 1 calc(20% - 20px); /* Cards take up 20% of row minus gap, adjust percentage as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
  position: relative; /* Ensure tooltip positioning works */
}

.admin-card:hover {
  background: var(--color-five);
}
