@media (max-width: 600px) {
  .record-player {
    transform: scale(0.8); /* Reduce the size */
  }

  .plinth {
    width: 280px; /* Reduce width */
    height: 210px; /* Reduce height */
  }

  .platter {
    width: 190px;
    height: 190px;
    top: 10px;
    left: 10px;
  }

  .vinyl {
    width: 175px;
    height: 175px;
    top: 15px;
    left: 15px;
  }

  .top-circle {
    width: 50px;
    height: 50px;
    top: 85px;
    left: 85px;
  }

  .headshell {
    width: 20px;
    height: 100px;
    left: 210px;
    top: 60px;
  }
}

.record-player-bg-div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 83vh;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--background-color);
  width: 100vw;
}

.record-player {
  position: relative;
  padding-bottom: 50px;
}

.plinth {
  position: relative;
  background-color: var(--tertiary-color);
  width: 400px;
  height: 300px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.plinth:before {
  content: "";
  position: absolute;
  width: 395px;
  height: 295px;
  background: radial-gradient(circle, white, transparent 50%),
    repeating-linear-gradient(3deg, rgb(0 0 0 / 3%) 0%, transparent 3%),
    repeating-linear-gradient(1deg, rgb(0 0 0 / 3%) 0%, transparent 1%),
    repeating-linear-gradient(0deg, rgb(0 0 0 / 3%) 0%, transparent 3%),
    repeating-linear-gradient(-1deg, rgb(0 0 0 / 3%) 0%, transparent 1%),
    repeating-linear-gradient(-3deg, rgba(0 0 0 / 3%) 0%, transparent 3%),
    radial-gradient(circle, white, #d27d2d);
  border-radius: 20px;
}

.plinth:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #808080;
  width: 30px;
  height: 30px;
  top: 20px;
  left: 20px;
  box-shadow: 0 230px #808080;
}

input#headshell {
  display: none;
}

input#volume-control {
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;
}

input#volume-control:focus {
  outline: none;
}

input#volume-control::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input#volume-control {
  position: absolute;
  z-index: 1;
  transform: rotate(-90deg) scale(0.25);
  left: 40%;
  top: 70%;
}

input#volume-control::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  width: 40px;
  height: 70px;
  background-color: #333533;
  border: none;
  border-radius: 2px;
  margin-top: -4px;
}

input#volume-control::-moz-range-thumb {
  cursor: pointer;
  width: 30px;
  height: 70px;
  background-color: #333533;
  border: none;
  border-radius: 2px;
}

input#volume-control::-ms-thumb {
  cursor: pointer;
  width: 30px;
  height: 70px;
  background-color: #333533;
  border: none;
  border-radius: 2px;
}

input#volume-control::-webkit-slider-runnable-track {
  background-color: #d6d6d6;
  border: none;
  margin: -30px;
}

input#volume-control::-moz-range-track {
  background-color: #333533;
  border: 30px solid black;
  outline: 2px solid #d6d6d6;
}

.platter {
  position: absolute;
  border-radius: 50%;
  width: 270px;
  height: 270px;
  background-color: var(--secondary-color);
  z-index: 2;
  top: 15px;
  left: 15px;
}

.platter:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #d6d6d6;
  width: 40px;
  height: 40px;
  border: 10px solid #2c2424;
  left: 280px;
  top: 30px;
}

.vinyl {
  position: absolute;
  background-image: repeating-radial-gradient(
    #181312,
    #181312 10%,
    #2c2424 15%
  );
  border-radius: 50%;
  width: 250px;
  height: 250px;
  z-index: 5;
  top: 25px;
  left: 25px;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.vinyl:before,
.vinyl:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1) transparent transparent transparent;
  border-width: 130px 50px 0 125px;
}

.vinyl:after {
  top: 170px;
  left: 60px;
  transform: rotate(-65deg);
}

.top-circle {
  position: absolute;
  z-index: 10;
  width: 70px;
  height: 70px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  top: 115px;
  left: 115px;
}

.top-circle:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #c0c0c0;
  top: 31px;
  left: 31px;
}

.headshell {
  width: 30px;
  height: 140px;
  position: absolute;
  border-right: 10px solid #cdd9eb;
  border-bottom: 10px solid #cdd9eb;
  border-bottom-right-radius: 50px;
  z-index: 15;
  left: 290px;
  top: 80px;
  cursor: pointer;
  transition: 0.3s;
  transform-origin: top;
}

.headshell:before,
.headshell:after {
  content: "";
  position: absolute;
}

.headshell:before {
  background-color: #808080;
  width: 20px;
  height: 30px;
  top: -20px;
  left: 25px;
}

.headshell:after {
  height: 0;
  width: 15px;
  border-top: 25px solid #808080;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  top: 133px;
  left: -20px;
  transform: rotate(90deg);
}

#headshell:checked + .headshell {
  transform: rotate(35deg);
}

@keyframes play {
  to {
    transform: rotate(360deg);
  }
}

#headshell:checked ~ .vinyl {
  animation: play 2s linear infinite 0.3s;
}

.hp-title-intro-bg {
  display: block;
  height: 17vh;
  width: auto;
  padding-bottom: 110px;
}

.hp-title-intro-bg-small {
  display: none;
}

@media (max-width: 600px) {
  .hp-title-intro-bg {
    display: none !important;
  }

  .hp-title-intro-bg-small {
    display: block !important;
    height: 20vh;
    width: auto;
    padding-bottom: 110px;
    animation: fadeIn 2s ease-in-out forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hp-title-intro-bg {
  display: block;
  opacity: 0; /* Start fully transparent */
  animation: fadeIn 2s ease-in-out forwards;
}

.retrohacks-txt {
  color: var(--background-color);
  font-size: 16px;
  margin: 5px;
  margin-top: 30px;
  text-align: center;
}
