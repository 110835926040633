@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.navigation {
  border-bottom: 2px solid var(--tertiary-color);
  background-color: var(--background-color);
  height: 3rem;
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100vw;
  padding-top: 5px;
  padding-bottom: 5px;
}

.left-side {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 33%;
}

.right-side {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 16%;
  position: relative;
}

.nav-link {
  padding-right: 3vw;
  color: var(--tertiary-color);
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s ease;
  font-family: "Montserrat", sans-serif;
}

.nav-link:hover {
  color: var(--secondary-color);
}

.nav-link-but {
  padding-right: 3vw;
  color: var(--tertiary-color);
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  background-color: transparent;
  border: none;
  font-family: "Montserrat", sans-serif;
  transition: 0.3s ease;
}

.nav-link-but:hover {
  color: var(--secondary-color);
}

.nav-link-img {
  padding-right: 1vw;
  color: var(--tertiary-color);
  height: 2.2rem;
  width: auto;
  margin-top: 5px;
}

.mlh-badge {
  max-width: 100px;
  min-width: 60px;
  position: absolute;
  /* right: 1%; */
  top: 0;
  z-index: 10000;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 30px;
  color: var(--tertiary-color);
  cursor: pointer;
  position: absolute;
  right: 1%;
  top: 0;
}

.nav-links {
  display: flex;
}

@media (max-width: 768px) {
  .nav-links.open {
    display: block;
  }

  .navigation {
    width: 100vw;
    height: 3rem;
  }

  .left-side {
    width: fit-content;
    margin-left: 0;
    justify-content: space-between;
  }

  .right-side {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
    width: 70vw;
  }

  .nav-link-img {
    margin-top: 5px;
    margin-left: 15px;
    height: 2.2rem;
  }

  .nav-link {
    padding: 10px 0;
    font-size: 18px;
    margin-left: 5px;
  }

  .nav-link-but {
    padding: 10px 0;
    font-size: 18px;
    display: block;
    margin-left: 5px;
  }

  .menu-toggle {
    height: 52px;
    display: block;
    margin-right: 2px;
  }

  .nav-links {
    display: none;
    /* width: 100%; */
    background-color: var(--background-color);
    border: 1px solid #dadada;
    z-index: 1000;
    min-height: fit-content;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    margin-top: 7.5vh;
    position: absolute;
    padding-left: 10px;
    width: fit-content;
    padding-right: 10px;
    right: 1px;
  }

  .mlh-badge {
    display: none;
    position: static;
    margin-top: 10px;
  }

  .nav-apply-button {
    display: flex;
    flex-direction: column;
  }

  .nav-link-but {
    text-align: start;
  }

  .nav-auth-section {
    display: flex;
    flex-direction: column;
  }
}
