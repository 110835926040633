.cofig-dash {
  height: fit-content;
}

.ev-status {
  color: var(--tertiary-color);
  margin-left: 15vw;
}

.selector-c {
  width: 17vw;
  height: 4vh;
  margin-left: 15vw;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
  margin-bottom: 1vh;
  font-size: 16px;
  padding: 3px;
}

.questions {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
}

.button-c {
  padding: 8px 13px 8px 13px;
  border-radius: 8px;
  width: fit-content;
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
  border: none;
  margin-bottom: 5vh;
  font-size: 14px;
}

label {
  color: var(--tertiary-color);
}

.hack-info-d {
  display: flex;
  flex-direction: column;
  padding-left: 15vw;
  margin-top: 1vh;
}

.hack-info-t {
  width: 17vw;
  margin-top: 1vh;
  margin-bottom: 3vh;
  height: 2.5vh;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
}

.hack-info-t::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

@media (max-width: 768px) {
  .selector-c {
    width: 40vw;
  }

  .hack-info-t {
    width: 40vw;
  }
}
