.get-users-ad {
  min-height: 69vh;
  width: 40vw;
  margin-left: 31vw;
  margin-top: 4vh;
}

.dashboard {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

.ad-dash-title {
  color: var(--tertiary-color);
  margin-top: 6vh;
  margin-left: 15vw;
  margin-bottom: 1.5vh;
  font-size: 40px;
}

p {
  color: var(--tertiary-color);
  margin-block-start: 7px;
  margin-block-end: 10px;
}

.searches-ad {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.find-selector-ad {
  width: 25vw;
  height: 3vh;
  margin-right: 5vw;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
}

.find-selector::placeholder {
  color: var(--tertiary-color);
}

.title-ad {
  color: var(--tertiary-color);
}

.button-ad {
  margin-top: 3vh;
  min-height: fit-content;
  height: 3vh;
  min-width: fit-content;
  width: 7vw;
  background-color: var(--tertiary-color);
  border: none;
  padding: 10px;
  font-weight: 520;
  font-size: 16px;
  color: var(--quaternary-color);
  border-radius: 5px;
}
