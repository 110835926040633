.title-div {
  margin-top: 1.5vh;
  margin-left: 15vw;
  border-bottom: var(--tertiary-color) 2px solid;
  width: 68vw;
  display: flex;
  flex-direction: row;
  align-items: start;
}

.title-div div {
  margin-left: 2vw;
}

.bottom-div {
  margin-top: 1.5vh;
  margin-left: 15vw;
  width: 68vw;
  display: flex;
  flex-direction: column;
}

.user-dash-title {
  color: var(--tertiary-color);
  font-size: 40px;
  padding-top: 0px;
  padding-bottom: 2px;
}

.user-dash-p {
  color: var(--tertiary-color);
  font-size: 18px;
  padding-bottom: 2px;
}

.user-dash-p-end {
  color: var(--tertiary-color);
  font-size: 18px;
  padding-bottom: 10px;
}

.user-dash-b {
  width: 12vh;
  margin-top: 2.5vh;
  height: fit-content;
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
  border: none;
  margin-bottom: 3vh;
  padding: 5px;
  margin-left: 2vw;
  border-radius: 8px;
}

.user-dash-b a {
  text-decoration: none;
  color: var(--quaternary-color);
}

.left-div {
  padding-right: 6vw;
}

.right-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.right-div div {
  display: flex;
  flex-direction: column;
}

.label-vu {
  padding-bottom: 12px;
  color: var(--tertiary-color);
  font-size: 24px;
  margin-top: 15px;
}

.confirm-deadline {
  color: var(--tertiary-color);
  width: 75%;
  margin-top: 5px;
  margin-bottom: 12px;
  border: 2px solid var(--tertiary-color);
  padding: 0 0.75em;
  background-color: var(--quaternary-color);
  height: 3vh;
  font-size: 16px;
}

.contact-info-h1 {
  margin-bottom: 2vh;
  margin-top: 2vh;
  padding: 0;
  color: var(--tertiary-color);
}

.cell-p {
  margin-bottom: 2vh;
  margin-top: 0;
  padding: 0;
  color: var(--tertiary-color);
}

.email-p {
  margin-bottom: 2vh;
  margin-top: 0;
  padding: 0;
  color: var(--tertiary-color);
}

.user-dash-h2 {
  color: var(--tertiary-color);
  font-size: 27px;
  font-weight: 400;
  padding-top: 0px;
  padding-bottom: 2px;
}

.bottom-div-inner {
  display: flex;
  flex-direction: row;
}

.user-dash-title-bd {
  color: var(--tertiary-color);
  font-size: 40px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-left: 2vw;
}

.button-vu {
  margin-top: 3vh;
  min-height: fit-content;
  height: 3vh;
  min-width: fit-content;
  width: 7vw;
  background-color: var(--tertiary-color);
  border: none;
  padding: 5px;
  color: var(--quaternary-color);
  border-radius: 8px;
}

.ad-vu-enterscore {
  color: var(--tertiary-color);
  width: 10vw;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 2px solid var(--tertiary-color);
  padding: 0 0.75em;
  background-color: var(--quaternary-color);
  height: 3vh;
  font-size: 16px;
}

.ad-vu-form {
  display: flex;
  flex-direction: column;
  margin-left: 3vw;
  margin-right: 3vw;
}

.ad-vu-scores-title {
  color: var(--tertiary-color);
  margin-top: 10px;
  margin-bottom: 2px;
}

.ad-vu-score {
  color: var(--tertiary-color);
  margin-bottom: 7px;
}

.ad-vu-list {
  margin-bottom: 20px;
  margin-right: 3vw;
  padding-left: 20px;
}

.ad-vu-averagescore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ad-vu-average-score-p {
  font-size: 22px;
}

.vu-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.vu-labelselect {
  display: flex;
  flex-direction: column;
}

.vu-actions {
  display: flex;
  flex-direction: row;
  margin-left: 0;
}

.ad-vu-score-a {
  color: var(--tertiary-color);
  font-size: 16px;
  text-decoration: none;
}
