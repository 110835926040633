/* root.css */
:root {
  /* --background-color: #0e273c;
  --secondary-color: #bf3131;
  --tertiary-color: #f1ead1;
  --quaternary-color: #000000;
  --color-five: #a5a3a3;
  --hp-white: #ffffff; */

  --background-color: #f1ead1;
  --secondary-color: #bf3131;
  --tertiary-color: #0e273c;
  --quaternary-color: #f1ead1;
  --color-five: #a5a3a3;
  --hp-white: #ffffff;
  --hp-black: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:hover {
  cursor: pointer;
}
