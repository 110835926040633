@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

/* General Styles */
.fullscreen-container {
  height: 100vh;
  /* width: 100vw; */
}

.background-container {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.background-image {
  width: auto;
  height: 100vh;
  z-index: 10;
  min-width: 100vw;
  /* object-fit: contain; */
}

/* Login Form Styles */
.login-form-container {
  position: absolute;
  inset: 0;
  display: flex;
  z-index: 20;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-form {
  position: relative;
  top: -17.25%;
  width: 43%;
  height: 33%;
  /* background-color: #e60000; Red background */
  text-align: center;
  overflow: hidden;
  /* width: 61rem; */
}

@media (max-width: 1200px) {
  .login-form {
    width: 55%;
  }

  .outer-divider {
    width: 55vw !important;
  }

  .background-image {
    min-width: fit-content !important;
    object-fit: contain !important;
  }
}

@media (max-width: 800px) {
  .login-form {
    width: 65%;
  }

  .outer-divider {
    width: 65vw !important;
  }

  .logo-s25 {
    height: 25vh !important;
    width: 18vw !important;
  }

  .title-s25 {
    margin-bottom: 0;
  }

  .background-image {
    min-width: fit-content !important;
    object-fit: contain !important;
  }
}

@media (max-width: 600px) {
  .login-form {
    width: 85%;
  }

  .outer-divider {
    width: 85vw !important;
  }

  .background-image {
    min-width: fit-content !important;
    object-fit: contain !important;
  }
}

@media (max-width: 475px) {
  /* Left Divider: 40% Width */
  .left-divider {
    display: none !important;
  }

  .lf-s25-welcome-message {
    display: flex !important;
    font-size: 20px;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .right-divider {
    margin: 0 !important;
  }

  .background-image {
    min-width: fit-content !important;
    object-fit: contain !important;
  }
}

.lf-s25-welcome-message {
  display: none;
}

.logo-s25 {
  height: 15vh;
  width: 12vw;
}

.s25-horizontal-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.s25-vertical-div {
  display: flex;
  flex-direction: row;
}

/* Outer Divider */
.outer-divider {
  display: flex;
  width: 43vw; /* Stretch to fill width */
  height: 100%; /* Stretch to fill height */
}

/* Left Divider: 40% Width */
.left-divider {
  flex: 0 0 40%; /* Fixed to 40% */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding: 10px;
  gap: 10px; /* Spacing between items */
  align-items: center;
}

/* Right Divider: 60% Width */
.right-divider {
  flex: 1; /* Occupies remaining 60% */
  padding: 10px;
  display: flex;
  flex-direction: column; /* Stack items */
  gap: 10px;
  margin-top: 3vh;
  margin-left: 3vw;
}

.title-s25 {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  margin-top: 3vh;
  margin-bottom: 0;
}
