html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.error-container {
  height: 80vh;
  width: 100vw;
  background-color: var(--background-color);
  display: flex;
  padding-top: 10vh;
  /* align-items: center; */
  justify-content: center;

  h1 {
    color: var(--hp-white);
  }
}
