/* Main Container */
.createapp-container {
  background-color: var(--background-color);
  width: 95vw;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 2.5vw;
}

/* Heading Styles */
.createapp-h2 {
  color: var(--tertiary-color);
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ca-option {
  color: var(--tertiary-color);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.section-container {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--hp-white);
  width: 80vw;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.list-ca li::marker {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

/* Inputs */
.ca-input {
  width: 80vw;
  padding: 12px;
  margin: 8px 0 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
  font-size: 16px;
  color: black;
  display: flex;
  flex-direction: column;
}

/* Buttons */
.ca-button {
  padding: 12px 20px 10px;
  background-color: var(--secondary-color);
  color: var(--hp-white);
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Form Container */

.createapp-form {
  display: flex;
  flex-direction: column;
}

/* Option Input for Multiple Choice / MultiSelect */
.createapp-form .options input[type="text"] {
  margin-top: 8px;
}

/* Add Question/Section Button Container */
.ca-button {
  background-color: var(--secondary-color);
  padding: 10px;
  width: 10vw;
  min-width: fit-content;
}

.createapp-form h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.ca-button-edit {
  background-color: var(--secondary-color);
  color: var(--hp-white);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;

  /* padding: 12px 20px 10px; */
  background-color: var(--secondary-color);
  color: var(--hp-white);
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.input-label {
  display: flex;
  flex-direction: column;
}

.section-title-ca {
  color: var(--hp-black);
  margin-left: 20px;
  text-decoration: underline;
}

.list-element {
  margin-top: 10px;
}

.add-option {
  margin-bottom: 20px;
  padding: 12px 20px 10px;
  background-color: var(--secondary-color);
  color: var(--hp-white);
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button-ca-div {
  margin-top: 3vh;
  width: 95vw;
  display: flex;
  justify-content: center;
}
