.dash-dashboard {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 73vh;
  padding-bottom: 6vh;
}

.row {
  margin-top: 3.5vh;
}

input[name="team-number"] {
  display: inline-block;
  width: 100px;
}

.dash-title {
  color: var(--tertiary-color);
  margin-left: 9vw;
  margin-bottom: 1.5vh;
  font-size: 40px;
}

.status {
  font-size: 40px;
  margin-top: 0;
  -webkit-text-stroke: 0.5px black;
  color: var(--tertiary-color);
}

.medium-3-columns {
  margin-top: 2vh;
  color: var(--tertiary-color);
  margin-left: 9vw;
  width: 20%;
}

.medium-9-columns {
  margin-top: 2vh;
  color: var(--tertiary-color);
  width: 60vw;
}

.row-dashboard-main {
  display: flex;
  min-height: 60vh;
}

.apply-button {
  margin-top: 3vh;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--tertiary-color);
  border: none;
  margin-top: 100px;
  font-size: 16px;
  color: var(--quaternary-color);
  text-decoration: none;
  border-radius: 3px;
}

.app-button-pad {
  margin-top: 30px;
}

.dashboard-main {
  @include breakpoint(medium only) {
    margin-top: 2em;
  }

  p {
    font-size: 1.1em;
    color: var(--tertiary-color);
  }
}

/* Start of Dashboard Progress */

.dashboard-progress {
  margin: 20px auto;
  overflow: hidden;
  text-align: center;

  @media (max-width: 900px) {
    & {
      display: none;
    }
  }

  .dashboard-progress-item {
    line-height: 22px;
    letter-spacing: 0.05em;

    &:not(.hide) {
      display: inline-block;
    }

    .dashboard-progress-item-icon {
      border-radius: 100%;
      border: 1.5px solid var(--secondary-color);
      color: var(--tertiary-color);
      background-color: var(--secondary-color);
      float: left;
      font-size: 20px;
      font-weight: bolder;
      height: 22px;
      margin-right: 10px;
      width: 22px;

      i {
        display: none;
      }
    }

    .dashboard-progress-item-icon-empty {
      border-radius: 100%;
      border: 1.5px solid var(--secondary-color);
      color: var(--tertiary-color);
      /* background-color: var(--secondary-color); */
      float: left;
      font-size: 20px;
      font-weight: bolder;
      height: 22px;
      margin-right: 10px;
      width: 22px;

      i {
        display: none;
      }
    }

    .dashboard-progress-item-text {
      @include font-subheading;
      color: var(--tertiary-color);
      font-size: 1rem;
    }

    &:not(:last-of-type):after {
      content: "\2192";
      float: right;
      margin: 0 12px;
      color: var(--secondary-color);
    }
  }
}

.dash-message {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

/* Start of Dashboard Progress */

.status-header {
  @include font-subsubheading;
  color: var(--tertiary-color);
  text-transform: uppercase;
  font-size: 1rem;
  margin: 10px 0 5px;
}

.status-header-list li {
  list-style-type: circle;
}

a.status-header-button,
button.status-header-button {
  margin: 10px 0 0;
}

.dashboard-waitlist-form {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
}

.dashboard-waitlist-label {
  padding-bottom: 10px;
}

.dashboard-waitlist-select {
  margin-bottom: 30px;
  width: 500px;
  min-width: fit-content;
  height: 30px;
  font-size: 16px;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
}

.dashboard-waitlist-button {
  background-color: var(--tertiary-color);
  color: var(--background-color);
  padding: 8px 13px 8px 13px;
  border-radius: 8px;
  font-size: 16px;
  width: fit-content;
  border: none;
}

@media (max-width: 600px) {
  .medium-3-columns {
    display: none;
  }

  .medium-9-columns {
    margin-left: 10vw;
    width: 80vw;
  }

  .dashboard-waitlist-select {
    width: 50vw;
  }
}
