.demographics-dashboard {
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

h1 {
  /* text-align: center; */
  /* color: var(--secondary-color); */
  /* font-size: 2.5em; */
  /* margin-bottom: 30px; */
  /* text-transform: uppercase; */
  /* letter-spacing: 2px; */
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

.chart-item {
  background-color: var(--hp-white);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* h2 {
  color: #5e3434;
  font-size: 1.5em;
  margin-bottom: 1px;
  text-align: center; */
/* border-bottom: 2px solid #6b555569; */
/* padding-bottom: 10px;
} */

@media (max-width: 768px) {
  .chart-grid {
    grid-template-columns: 1fr;
  }
}

.chart-item:nth-child(4n + 1) {
  background-color: #e6f8e6bc;
}

.chart-item:nth-child(4n + 2) {
  background-color: #d7ffff83;
}

.chart-item:nth-child(4n + 3) {
  background-color: #fffad772;
}

.chart-item:nth-child(4n + 4) {
  background-color: #ffe6ee72;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.1em;
  }
}

html {
  scroll-behavior: smooth;
}

.chart-item {
  animation: fadeIn 3s ease-in;
}

.container-fluid {
  height: 100vh;
}
