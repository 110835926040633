.button-resume {
  margin-top: 3vh;
  min-height: fit-content;
  height: 3vh;
  min-width: fit-content;
  width: 7vw;
  background-color: var(--tertiary-color);
  border: none;
  padding: 8px 13px 8px 13px;
  border-radius: 8px;
  font-weight: 520;
  font-size: 16px;
  color: var(--quaternary-color);
}
