.ae-options {
  display: flex;
  flex-direction: column;
}

.ae-label {
  margin-top: 20px;
}

.ae-button {
  margin-top: 10px;
  padding: 5px;
  color: white;
}

.ae-date {
  width: 17vw;
  margin-top: 10px;
  height: 3.5vh;
  background-color: var(--background-color);
  color: var(--tertiary-color);
  border: 2px solid var(--secondary-color);
}

.ae-date::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
