/* :root {
  --background-color: #000000;
  --secondary-color: #c70314;
  --tertiary-color: #ea41ba;
  --quaternary-color: #000000;
  --color-five: #797979;
  --hp-white: #ffffff;
} */

/* Prevent scrolling on the main page */
html,
body {
  height: 100%;
  margin: 0;
  /* overflow: hidden; */
}

/* Add this to your existing CSS file */

/* All Graphs Styling */
.graph-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Responsive grid */
  gap: 20px; /* Space between graph items */
  padding: 20px; /* Padding around the grid */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 70vh; /* Set a max height for scrolling */
}

/* Individual Graph Item Styling */
.graph-item {
  background-color: var(--quaternary-color);
  border: 1px solid var(--color-five); /* Add border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Space around the graph */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Sidebar and Main Container Styling */
.sidebar-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden; /* Prevent main screen from scrolling */
}

.sidebar {
  width: 300px;
  min-width: 200px;
  max-width: 300px;
  padding: 20px;
  background-color: var(--background-color);
  border-right: 1px solid var(--color-five);
  overflow-y: auto; /* Allow vertical scrolling in the sidebar */
}

.graph-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* Change to column */
  background-color: var(--quaternary-color);
  text-align: center;
  padding-top: 0; /* Align graph to the top */
  align-items: center; /* Center contents horizontally */
}

.graph-wrapper {
  width: 100%;
  max-width: 1000px; /* Reduced the size of the graphs */
  height: 450px; /* Set a fixed height for better control */
  margin: 0 auto; /* Center the wrapper */
  padding: 20px;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center items */
  align-items: center; /* Center items vertically */
}

.pie-chart {
  max-width: 200%; /* Ensure the chart is responsive */
  height: 600px; /* Maintain aspect ratio */
}

/* Legend Styling */
.legend {
  display: flex;
  flex-direction: column; /* Change to row to align items horizontally */
  flex-wrap: wrap; /* Allow items to wrap if they exceed the width */
  justify-content: space-around; /* Space items evenly */
  width: 100%; /* Full width of the graph-wrapper */
  max-width: 1000px; /* Optional: limit maximum width */
}

.legend-item {
  margin: 10px 20px; /* Space between legend items */
  display: flex; /* Allow for colored squares and text */
  align-items: center; /* Align items vertically */
  flex-basis: 30%; /* Control the basis of each item, adjust as necessary */
}

.legend-color {
  width: 20px; /* Fixed width for color squares */
  height: 20px; /* Fixed height for color squares */
  margin-right: 10px; /* Space between color square and text */
  border-radius: 3px; /* Optional: rounded corners */
}

h2 {
  margin: 20px 0;
  color: var(--hp-white);
  /* text-align: center; */
}

/* Sidebar List Styling */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  cursor: pointer;
  padding: 10px;
  font-size: 1rem;
  color: var(--hp-white);
  transition: background-color 0.3s;
}

.sidebar ul li:hover {
  background-color: var(--secondary-color);
}

/* Radio Button Styles */
.toggle-container {
  display: flex;
  justify-content: space-around; /* Space the buttons evenly */
  margin-top: 20px;
  padding-bottom: 20px;
  /* padding-left: 15px; */
}

.toggle-input {
  display: none; /* Hide the actual radio button */
}

.toggle-label:hover {
  background-color: var(--secondary-color); /* Background on hover */
}

.toggle-input:checked + .toggle-label {
  background-color: var(--tertiary-color); /* Background when selected */
  border-color: var(--secondary-color); /* Change border color when selected */
}

/* Media Queries for Responsive Behavior */
@media (max-width: 1024px) {
  .sidebar {
    width: 250px;
    font-size: 0.9rem;
  }

  .graph-wrapper {
    max-width: 500px; /* Adjust graph wrapper size */
    height: 350px; /* Adjust graph height */
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    font-size: 0.8rem;
    padding: 10px;
  }

  .graph-wrapper {
    max-width: 400px;
    height: 300px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 150px;
    font-size: 0.7rem;
    padding: 5px;
  }

  .graph-container {
    padding: 10px;
  }

  .graph-wrapper {
    max-width: 300px;
    height: 250px;
  }
}

/* .toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--secondary-color); 
  border-radius: 5px; 
  padding: 5px 7px; 
  background-color: transparent; 
  color: var(--hp-white);
  transition: background-color 0.3s, border-color 0.3s; 
}

.toggle-input:checked + .toggle-text {
  background-color: var(--tertiary-color); 
  color: var(--hp-white); 
  border-radius: 5px;
  padding: 5px 10px;
} */

.toggle-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  padding: 5px 7px;
  background-color: transparent;
  color: var(--hp-white);
  transition: background-color 0.3s, border-color 0.3s;
  text-align: center;
}

.toggle-input:checked + .toggle-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(199, 3, 20, 1);
  border-color: var(--secondary-color);
  color: var(--hp-white);
  border-radius: 5px;
  padding: 5px 7px;
  margin: 0;
}
