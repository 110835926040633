.whitelistemails-background {
  background-color: var(--background-color);
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

.table-container {
  /* overflow-x: hidden; */
  margin-bottom: 5vh;
}

@media (max-width: 768px) {
  .table-container {
    overflow-x: scroll;
  }
}

table {
  /* margin-left: 30vh; */
  /* width: 40vh; */
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: var(--color-five);
}

/* Alternating row colors */
.even-row {
  background-color: #dadada;
}

.odd-row {
  background-color: var(--color-five);
}

/* Make the first row sticky */
thead tr th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.delete-admin-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: black;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: var(--secondary-color);
  }
}